<template>
  <div>
    <spacial></spacial>
  </div>
</template>

<script>

import spacial from "../../components/spacial/index.vue";
export default {
    components:{
        spacial
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>